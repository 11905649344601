.tabs_speed {
  margin-top: 100px;

  @media (max-width: 767px) {
    margin-top: 80px;
  }

  .MuiBox-root {
    flex-direction: column;
    padding-left: 0;
    padding-right: 0;
  }

  .MuiTabs-root {
    margin-bottom: 50px;
  }

  .MuiButtonBase-root {
    padding-right: 16px;
  }

  .MuiTabs-flexContainer {
    justify-content: center;

    &:after {
      display: none;
    }
  }

  .chart_speed_container {
    width: 50%;

    @media (max-width: 767px) {
      width: 100%;
    }
  }

  .MuiTypography-root {
    display: flex;
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      gap: 50px;
    }
  }
.site_img_container {
  display: flex;
}
  .site_img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;

    .frame_img {
      position: relative;

      &.desctop_prev:before, &.mob_prev:before {
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        z-index: 2;
        position: absolute;
      }

      &.desctop_prev {
        width: 500px;
        height: 285px;

        @media (max-width: 991px) {
          width: 368px;
          height: 211px;
        }

        &:before {
          background: url("../img/desc_c.png") no-repeat center;
          background-size: contain;
        }

        .scroll_img_container {
          width: 395px;
          height: 260px;
          margin: 0 auto;
          border-radius: 16px 16px 0 0;
          overflow: hidden;
          position: relative;
          @media (max-width: 991px) {
            width: 293px;
            height: 197px;
          }
        }
      }

      &.mob_prev {
        width: 184px;
        height: 370px;
        @media (max-width: 991px) {
          width: 116px;
          height: 233px;
        }

        &:before {
          background: url("../img/mob_c.png") no-repeat center;
          background-size: contain;
        }

        .scroll_img_container {
          width: 166px;
          height: 100%;
          margin: 0 auto;
          border-radius: 25px;
          overflow: hidden;
          @media (max-width: 991px) {
            width: 102px;
          }
        }
      }

      .site_img_scroll {
        width: 100%;
        transform: translateY(0);
        animation: scrollTop 50s ease-in-out infinite;
      }


    }
  }
}

@keyframes scrollTop {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-93.5%);
  }
  100% {
    transform: translateY(0);
  }
}