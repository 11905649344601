.inner_section {
  padding-top: 80px;
  margin-top: 108px;
  @media (max-width: 767px) {
    padding-top: 0;
    margin-top: 90px;
  }

}

.project_container {
  background: url("../img/proj_bg.svg") no-repeat top right;
  background-size: 70%;
}

.bread {
  margin-bottom: 50px;
  a {
    background-image: linear-gradient(to left, #F63737 50%, #fff 50%);
    background-size: 200% 100%;
    color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    transition: all 550ms ease-in-out;
    @media (max-width: 767px) {
      font-size: 15px;
    }

    &:hover {
      background-position: 100% 0;
    }
  }
}

.project_title {
  .logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 50px;
    @media (max-width: 767px) {
      gap: 25px;
    }

    .project_logo {
      width: 500px;
      max-width: 100%;
      @media (max-width: 767px) {
        width: 270px;
      }

      &.add_bg {
        background: #ede8e2;
        padding: 10px;
      }

    }



    .project_sub {
      font-size: 17px;

      @media (max-width: 767px) {
        font-size: 16px;
        text-align: center;
      }
    }

  }
}

.problem_description {
  margin-top: 100px;
  padding-bottom: 65px;
  position: relative;

  @media (max-width: 767px) {
    margin-top: 80px;
  }

  &:before {
    content: '';
    width: 270px;
    position: absolute;
    height: 1px;
    background: #fff;
    display: block;
    bottom: 0;
    left: calc(50% - 135px);
  }
  p {
    font-size: 35px;
    text-align: center;
    padding: 0 20px;

    @media (max-width: 991px) {
      font-size: 25px;
    }
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }
}

.top_achievements {
  display: flex;
  justify-content: space-between;
  margin-top: 130px;

  @media (max-width: 767px) {
    margin-top: 80px;
    flex-direction: column;
    gap: 50px;
  }

  .achievement {
    display: flex;
    flex-direction: column;
    gap: 40px;

    @media (max-width: 767px) {
      gap: 10px;
    }

    .count {
      font-weight: 400;
      font-size: 25px;
      line-height: 100%;
      color: #F63737;
      padding-bottom: 30px;
      border-bottom: 1px solid #F63737;

      @media (max-width: 767px) {
        padding-bottom: 10px;
      }
    }
  }
}

.solution {
  margin-top: 130px;
  @media (max-width: 767px) {
    margin-top: 80px;
  }

  .project_solution {
    display: flex;
    flex-direction: column;
    gap: 25px;
    li {
      font-size: 25px;
      position: relative;

      span {
        color: #E3A1A1;
        font-weight: 600;
        display: block;

        &:before {
          content: '-';
          margin-right: 25px;
        }
      }
    }
  }
  .title_solution {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;

    svg {
      @media (max-width: 767px) {
        width: 172px;
        height: 56px;
      }
    }
    text {
      text-align: center;
      font-size: 85px;
      font-weight: 900;
      stroke-width: 1px;
      stroke: #fff;
      alignment-baseline: central;
      fill: none;

      @media (max-width: 767px) {
        font-size: 45px;
        stroke-width: 0.5px;
      }
    }
  }

  p {
    font-size: 25px;
    margin-bottom: 40px;
    text-align: center;

    @media (max-width: 767px) {
      font-size: 18px;
      margin-bottom: 20px;
    }
  }
}

.video_project {
  margin: 100px 0;

  @media (max-width: 767px) {
    margin: 80px 0;
  }
  .video_container {
    width: 900px;
    height: 500px;
    margin: 0 auto;
    position: relative;
    max-width: 100%;

    @media (max-width: 991px) {
      width: 600px;
      height: 357px;
    }
    @media (max-width: 767px) {
      width: 345px;
      height: 199px;
    }

    &:before {
      content: '';
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: url("../img/desc_c.png") no-repeat center;
      background-size: contain;
    }

    video {
      max-width: 700px;
      margin: 0 auto;
      display: block;
      padding-top: 15px;
      @media (max-width: 991px) {
        max-width: 478px;
      }

      @media (max-width: 767px) {
        padding-top: 5px;
        max-width: 270px;
      }
    }
  }
}