@import './variables';

.section-footer {
  background: $footer_color;
  border-radius: 40px 40px 0 0;
  padding: 70px 0 135px;
  margin-top: 150px;

  @media (max-width: 767px) {
    padding: 50px 0 50px;
  }
}
.footer-container {
  display: flex;
  justify-content: space-between;
  @media (max-width: 1000px) {
    flex-wrap: wrap;
    gap: 50px;
    padding: 0 15px;
  }
  @media (max-width: 845px) {
    flex-direction: column;
    align-items: flex-start;
  }
  @media (max-width: 767px) {
    align-items: center;
  }
  .logo-col {
    text-align: center;
    @media (max-width: 1000px) {
      width: 100%;
    }
  }
  .footer-col {
    @media (max-width: 767px) {
      width: 334px;
    }
  }

  .copyright {
    font-weight: 400;
    font-size: 22px;
    line-height: 100%;
    color: #8f889a;
    margin-top: 30px;
    @media (max-width: 1000px) {
      margin-top: 0;
    }
    @media (max-width: 767px) {
      font-size: 18px;
    }
  }
  .logo {
    padding-top: 39px;
    @media (max-width: 1000px) {
      padding-top: 0;
    }
  }

  .footer-col-title {
    font-family: "TT-Firs-Neue-Bold", sans-serif;
    font-weight: 300;
    font-size: 30px;
    line-height: 99%;
    letter-spacing: -0.03em;
    margin-bottom: 28px;
    @media (max-width: 767px) {
      font-size: 20px;
    }
  }

  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 25px 80px;

    a {
      font-weight: 400;
      font-size: 22px;
      line-height: 100%;
      letter-spacing: -0.01em;
      color: #8f889a;
      transition: 0.2s ease;
      @media (max-width: 767px) {
        font-size: 18px;
      }

      &:hover {
        color: $second_main_color;
      }
    }
  }

  .contact-container {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .contact-element {
    display: flex;
    gap: 60px;
    @media (max-width: 767px) {
      gap: 20px;
    }

    p {
      font-weight: 400;
      font-size: 22px;
      line-height: 100%;
      letter-spacing: -0.01em;
      color: #8f889a;
      min-width: 100px;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }

    a {
      font-weight: 400;
      font-size: 22px;
      line-height: 100%;
      letter-spacing: -0.01em;
      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
  }
}