@import './variables';

.section-nav {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
  background: $dark_color;
  padding-bottom: 30px;
  @media (max-width: 991px) {
    z-index: 999;
    padding-top: 20px;
    position: fixed;
    padding-bottom: 20px;
  }
  .navigation {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .onscroll {
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      height: 1px;
      transition: width 0.2s ease-in-out;
      background: #f6373796;
      box-shadow: 0 0px 8px 1px $main_color;
  }

  nav {
    @media (max-width: 991px) {
      position: absolute;
      right: -100%;
      background: #000000;
      z-index: 999;
      top: 84px;
      padding: 25px;
      box-shadow: 0 0px 7px 0px $main_color;
      border-radius: 20px 0 0 20px;
      transition: 0.5s cubic-bezier(0,.06,.66,1.57);

      &.active {
        right: 0;
      }
    }

    ul {
      display: flex;
      gap: 40px;

      @media (max-width: 991px) {
        flex-direction: column;
      }


      a {
        padding: 8px 33px;
        position: relative;
        transition: color 0.3s ease;

        @media (max-width: 991px) {
          font-size: 18px;
          padding: 8px 0;
        }
        &:before, &:after {
          content: "";
          width: 50%;
          height: 1px;
          background: #d9d9d9;
          position: absolute;
          opacity: 0.3;
          transition: width 0.3s cubic-bezier(0.6,-0.28,0.74,0.05);

          @media (max-width: 991px) {
            display: none;
          }
        }

        &:before {
          top: 0;
          right: 0;
        }
        &:after {
          bottom: 1px;
          left: 0;
        }
        &:hover, &.active {
          color: $main_color;
          &:before, &:after {
            width: 100%;
          }
        }
      }
    }
  }
}

.ham {
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: -20px;
  margin-bottom: -25px;
}

.line {
  fill:none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #ce0000;
  stroke-width:5.5;
  stroke-linecap:round;
}

.ham8 {
  .top {
    stroke-dasharray: 40 160;
  }
  .middle {
    stroke-dasharray: 40 142;
    transform-origin: 50%;
    transition: transform 400ms;
  }
  .bottom {
    stroke-dasharray: 40 85;
    transform-origin: 50%;
    transition: transform 400ms, stroke-dashoffset 400ms;
  }
  &.active {
    .top {
      stroke-dashoffset: -64px;
    }
    .middle {
      //stroke-dashoffset: -20px;
      transform: rotate(90deg);
    }
    .bottom {
      stroke-dashoffset: -64px;
    }
  }
}

.hamburder {
  display: none;

  @media (max-width: 991px) {
    display: block;
    position: relative;
    z-index: 99999;
  }
}