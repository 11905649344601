@import './variables';
@import './fonts';

.about_section {
  margin-top: 293px;

  @media (max-width: 991px) {
    margin-top: 150px;
  }

  @media (max-width: 767px) {
    margin-top: 100px;
  }

  &:before {
    content: '';
    width: 1098px;
    height: 1098px;
    position: absolute;
    top: -559px;
    left: -200px;
    z-index: -1;
    background: url("../img/blur/blur2.svg") no-repeat center;

    @media (max-width: 991px) {
      width: 700px;
      height: 700px;
      background-size: contain;
      top: -199px;
      left: -83px;
    }
    @media (max-width: 767px) {
      top: 0;
      left: 0;
      width: 100%;
    }
  }

  &__container {
    display: flex;
    gap: 65px;
  }

  &__image {
    @media (max-width: 991px) {
      display: none;
    }
  }

  .img_wrap {
    position: relative;
    padding: 35px 0 0 47px;


    &.tab_img {
      display: none;
      @media (max-width: 991px) {
        display: block;
      }
    }

    &:before {
      content: '';
      width: 265px;
      height: 265px;
      position: absolute;
      top: 0;
      left: 0;
      border: 2px solid $main_color;
      border-radius: 45px;
      box-shadow: 0 0 0 0 #F63737;
      animation: none;
      transition: 0.3s ease-in-out;
    }

    &:hover {
      &:before {
        top: 33px;
        left: 46px;
        border-radius: 32px;
        box-shadow: 0 0px 18px 2px #F63737;
        animation: pulse 1.5s infinite;
      }

    }


    img {
      max-width: 265px;
      aspect-ratio: 1 / 1;
      border-radius: 30px;
      filter: grayscale(100%);
      transition: filter 0.3s ease-in-out;
      position: relative;
      z-index: 3;
      margin: 0;
    }
  }

  &__container {
    &.active .img_wrap img {
      filter: grayscale(0);
    }
  }

  &__text {
    p {
      margin-bottom: 32px;

      &:first-of-type {
        display: flex;
        gap: 50px;
        align-items: center;

        @media (max-width: 767px) {
          flex-direction: column-reverse;
        }
      }
    }
  }

  &__technology {

    @media (max-width: 991px) {
      width: 100%;
      overflow-x: scroll;
      scrollbar-width: none;

    }

    .carousel-large {
      display: flex;
      gap: 40px 100px;
      flex-wrap: wrap;
      justify-content: center;


      @media (max-width: 991px) {
        flex-wrap: nowrap;
        gap: 50px;
        animation: scrollAnimation 20s linear infinite;
      }
    }

    img {
      max-height: 70px;
      opacity: 0.7;
      filter: grayscale(100%);
      transition: 0.5s ease;

      &:hover {
        transform: rotate3d(0, 1, 0, 360deg);
        filter: grayscale(0%);
      }
    }
  }
}


@keyframes scrollAnimation {
  0%, 100% {
    transform: translateX(0); /* Начальная и конечная позиции */
  }
  50% {
    transform: translateX(calc(-60% - 20px)); /* Прокрутка влево до конца */
  }
}

@keyframes pulse {
  0% {
    box-shadow: 0 0px 18px 2px #F63737;
  }
  10% {
    box-shadow: 0 0 15px 2px #F63737;
  }
  20% {
    box-shadow: 0 0 17px 2px #F63737;
  }
  50% {
    box-shadow: 0 0 9px 1px #F63737;
  }
  80% {
    box-shadow: 0 0 14px 2px #F63737;
  }
  90% {
    box-shadow: 0 0 12px 2px #F63737;
  }
  100% {
    box-shadow: 0 0 16px 2px #F63737;
  }
}