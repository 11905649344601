@import './variables';
@import './fonts';

.tab_container {
  .MuiButtonBase-root {
    font-family: "Gilroy", sans-serif;
    font-weight: 400;
    font-size: 30px;
    text-transform: none;
    color: #dadada;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    padding-right: 48px;
    @media (max-width: 767px) {
      font-size: 20px;
      padding-right: 16px;
    }
  }
  .MuiButtonBase-root.Mui-selected {
    color: $main_color;
  }
  .MuiTabs-indicator{
    background-color: $main_color;
    z-index: 2;
  }
  .MuiTabs-root {
    border-color: none;
    min-width: max-content;
    @media (max-width: 991px) {
      margin-bottom: 50px;
    }
  }

  .MuiTabs-flexContainer {
    position: relative;

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: rgba(217, 217, 217, 0.6);

      @media (max-width: 991px) {
        display: none;
      }
    }

    @media (max-width: 991px) {
     display: flex;
    }
  }
  .MuiBox-root {
    padding: 0 0 0 81px;
    @media (max-width: 991px) {
      padding: 0 16px 0 16px;
    }
    @media (max-width: 767px) {
      padding: 0;
    }
  }
}