@import './variables';
@import './fonts';

.head_section {
  display: flex;
  gap: 50px;
  padding-top: 95px;
  position: relative;
  z-index: 3;
  justify-content: space-between;
  margin-top: 100px;
  @media (max-width: 991px) {
   flex-direction: column;
    padding-top: 50px;
  }
  @media (max-width: 767px) {
    padding-top: 30px;
    margin-top: 64px;
  }

  &:after {
    content: '';
    width: 781px;
    height: 572px;
    background: url("../img/oct.svg") no-repeat center;
    background-size: contain;
    position: absolute;
    bottom: -76px;
    left: calc(50% - 274px);
    z-index: -1;

    @media (max-width: 1100px) {
      width: 500px;
      bottom: -49px;
      left: 276px;
    }
    @media (max-width: 991px) {
      left: auto;
      right: 0;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }


  &__img {
    position: relative;
    z-index: 1;
    @media (max-width: 991px) {
      display: none;
    }
    &:after {
      content: '';
      width: 698px;
      height: 698px;
      background: url("../img/blur/blur_1.svg") no-repeat center;
      background-size: contain;
      display: block;
      position: absolute;
      bottom: -380px;
      right: 0;
      z-index: -1;
      @media (max-width: 1550px) {
        right: -15px;
      }
    }
    img {
      width: auto;
      height: 546px;
      border-radius: 30px;
      filter: grayscale(100%);
      transition: filter 0.3s ease-in-out;

      &.hovered {
        filter: grayscale(0);
      }
      @media (max-width: 1550px) {
        height: 470px;
      }


    }
  }

  &__textContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    justify-content: center;
    max-width: 560px;

    @media (max-width: 991px) {
      max-width: 100%;
    }
  @media (max-width: 767px) {
    .main_btn {
      margin: 0 auto;
      position: relative;
      z-index: 2;
    }
  }
    span.head_underTitle {
      font-size: 22px;

      @media (max-width: 767px) {
        font-size: 18px;
      }
    }
    h1 {
      font-size: $h1_title;
      line-height: 109%;
      display: flex;
      flex-direction: column;

      .color_second {
        color: $second_main_color;
      }

      @media (max-width: 991px) {
        font-size: $h1_title_tab;
      }
      @media (max-width: 870px) {
        max-width: 55%;
      }
      @media (max-width: 767px) {
        max-width: 100%;
        font-size: $h1_title_mob;
      }

    }
    p {
      font-size: 20px;
      line-height: 140%;

      @media (max-width: 767px) {
        font-size: 16px;
      }
    }

    .header-text {
      @media (max-width: 991px) {
        display: flex;
        justify-content: space-between;
        gap: 100px;
      }
      @media (max-width: 767px) {
        flex-direction: column;
        gap: 30px;
        align-items: center;
      }

      .img-mob-container {
        display: none;
        position: relative;
        z-index: 2;
        @media (max-width: 991px) {
          display: inline-block;
        }
        &:after {
          content: '';
          width: 413px;
          height: 476px;
          background: url('../img/blur/blur_1.svg') no-repeat center;
          background-size: contain;
          display: block;
          position: absolute;
          bottom: 112px;
          right: -82px;
          z-index: -1;

          @media (max-width: 991px) {
            right: 0;
            bottom: -223px;
          }
        }
      }
      .mob-image {
          width: auto;
          max-width: 211px;
          height: 250px;
          border-radius: 30px;
          filter: grayscale(100%);
          transition: filter 0.3s ease-in-out;
          margin-top: -110px;

        @media (max-width: 767px) {
          margin-top: 0;
          width: fit-content;
        }
      }
    }
  }
}