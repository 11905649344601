@import './variables';
@import './fonts';

.section_experience {
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root {
    font-family: "Gilroy", sans-serif;
    font-weight: 400;
    font-size: 30px;
    text-transform: none;
    color: #dadada;
    align-items: flex-end;
    -webkit-align-items: flex-end;
    padding-right: 48px;
  }
  .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
    color: $main_color;
  }
  .css-10d9dml-MuiTabs-indicator {
    background-color: $main_color;
    z-index: 2;
  }
  .css-35zqo5-MuiTabs-root {
    border-color: none;
    position: relative;

    &:after {
      content: '';
      width: 1px;
      height: 100%;
      position: absolute;
      right: 0;
      top: 0;
      background: rgba(217, 217, 217, 0.6);
    }
  }
  .css-k008qs {
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
  }

  .work {
    &_position {
      font-weight: 400;
      font-size: 30px;
      line-height: 93%;
      color: $color_white;
      margin-bottom: 15px;
      @media (max-width: 991px) {
        font-size: 20px;
      }
    }

    &_period {
      font-weight: 400;
      font-size: 22px;
      line-height: 127%;
      color: $second_main_color;
      margin-bottom: 35px;
      display: block;
      @media (max-width: 991px) {
        font-size: 18px;
      }
    }

    &_achievements {
      margin-left: 20px;

      li {
        list-style-type: disc;
        margin-bottom: 15px;
        font-weight: 300;
        font-size: 20px;
        line-height: 150%;
        color: $experience_list;
        @media (max-width: 991px) {
          font-size: 16px;
        }
      }
    }
  }
}