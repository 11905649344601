//colors
$main_color: #F63737;
$second_main_color: #E3A1A1;
$color_white: #fff;
$main_text_color: #fff;
$dark_color: #000;
$experience_list: #dadada;
$footer_color: #15141A;

// fonts
$title_size: 30px;
$h1_title: 55px;
$h1_title_tab: 45px;
$h1_title_mob: 35px;

// Functions
