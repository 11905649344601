@import './variables';

.projects_container {
  display: flex;
  flex-direction: column;
  gap: 140px;
  @media (max-width: 991px) {
    gap: 100px;
  }
  @media (max-width: 767px) {
    gap: 50px;
  }
}

.projects_section {
  position: relative;

  &:before {
    content: '';
    width: 1098px;
    height: 1098px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background: url("../img/blur/blur2.svg") no-repeat center;
    @media (max-width: 1145px) {
      width: 100%;
      max-width: 100%;
      max-height: 1000px;
      background-size: contain;
      top: -300px;
    }
  }

  &:after {
    content: '';
    width: 935px;
    height: 768px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
    background: url("../img/stars_bg.svg") no-repeat center;
  }
}

.project_block {
  display: flex;
  justify-content: space-between;

  @media (max-width: 1145px) {
    flex-direction: column-reverse;
  }

  .project_img {
    filter: grayscale(100%);
    transition: filter 0.3s ease-in-out;
    @media (max-width: 767px) {
      margin-top: 25px;
    }

  }

  .project_title_container {
    width: 100%;
  }

  &__content {
    padding-top: 40px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    @media (max-width: 991px) {
      padding-top: 0;
    }

    .project_name {
      font-weight: 600;
      font-size: 34px;
      text-align: right;
      margin-bottom: 35px;
      @media (max-width: 767px) {
        margin-bottom: 25px;
        font-size: 20px;
      }


      &.read_more_pro {
        cursor: pointer;

        a {
          background-image: linear-gradient(#fff 50%, #F63737 50%);
          background-size: 100% 200%;
          color: transparent;
          background-clip: text;
          -webkit-background-clip: text;
          transition: all 550ms ease-in-out;

          &:hover {
            background-position: 0% 100%;
          }
        }

      }
    }

    .tags {
      display: flex;
      gap: 30px;
      @media (max-width: 767px) {
        flex-wrap: wrap;
        gap: 15px;
      }

      span {
        font-weight: 400;
        font-size: 24px;
        color: #d9d9d9;

        @media (max-width: 767px) {
          font-size: 18px;
        }
      }
    }
  }

  &__description {
    position: relative;

    p {
      font-weight: 400;
      font-size: 24px;
      text-align: right;
      position: absolute;
      width: 746px;
      background: #252020;
      padding: 33px 45px;
      display: block;
      box-sizing: border-box;
      top: 0;
      right: 0;
      line-height: 29px;
      @media (max-width: 1145px) {
        top: 87px;
      }
      @media (max-width: 991px) {
        max-width: 100%;
        font-size: 20px;
      }

      .highlighted {
        color: $second_main_color;
      }

      @media (max-width: 767px) {
        padding: 15px 20px;
        font-size: 18px;
        position: relative;
        top: 0;
      }
    }
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    gap: 14px;

    @media (max-width: 767px) {
      margin-top: 25px;
    }

    a {
      display: block;
    }
  }

  &.revers {
    flex-direction: row-reverse;
    @media (max-width: 1145px) {
      flex-direction: column-reverse;
    }

    .project_block__content, .project_block__footer {
      align-items: flex-start;
    }

    .project_block__content .project_name {
      text-align: left;
    }

    .project_block__description p {
      right: auto;
      left: 0;
      text-align: left;
    }
  }

  &.active {
    .project_img {
      filter: grayscale(0);
    }
  }
}