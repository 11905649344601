@import './variables';
@import './fonts';

/* Reset styles */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* Base styles */
body {
  font-size: 20px;
  line-height: 1.5;
  color: $main_text_color;
  background-color: $dark_color;
  box-sizing: border-box
}


html::-webkit-scrollbar {
  display: none;
}
html {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

body, p, a {
  font-family: "Gilroy", sans-serif;
}

h2, h3, h4, h5, h6 {
  font-family: "TT-Firs-Neue-Bold", sans-serif;
  font-weight: 900;
}

a {
  color: inherit;
  text-decoration: none;
  box-sizing: border-box;
}

a:hover {
  text-decoration: none;
}

ul, li {
  list-style-type: none;
}

div {
  box-sizing: border-box
}

/* end Reset styles */

.container {
  max-width: 1325px;
  margin: 0 auto;
  @media (max-width: 1550px) {
    padding: 0 15px 0 80px;
  }

  @media (max-width: 1300px) {
    padding: 0 15px;
  }
}

.container-header {
  max-width: 1535px;
  margin: 0 auto;

  @media (max-width: 1550px) {
    max-width: 1325px;
    padding: 0 15px 0 80px;
  }

  @media (max-width: 1300px) {
    padding: 0 15px;
  }
}

p {
  font-weight: 300;
  font-size: 20px;
  line-height: 140%;
  @media (max-width: 991px) {
    font-size: 18px;
  }

  @media (max-width: 767px) {
    font-size: 16px;
  }
}

.main_btn {
  font-size: 22px;
  line-height: 111%;
  padding: 21px 35px;
  border: 1px solid #fff;
  border-radius: 50px;
  display: inline-block;
  cursor: pointer;
  user-select: none;
  width: max-content;
  transition: background .3s ease, color 0.3s ease;
  @media (max-width: 767px) {
    font-size: 18px;
  }

  &:hover {
    background: rgba(248, 248, 248, 0.28);
    color: #fff;
  }

  &.btn_red {
    color: #f63737;
    border: 1px solid #f63737;

    &:hover {
      background: #f6373747;
      color: #fff;
    }
  }
}

.home_section {
  margin-top: 215px;
  position: relative;
  z-index: 2;
  @media (max-width: 991px) {
    margin-top: 150px;
  }
  @media (max-width: 767px) {
    margin-top: 100px;
  }
}

.section_title {
  font-weight: 700;
  font-size: 40px;
  margin-bottom: 32px;

  @media (max-width: 991px) {
    font-size: 35px;
  }

  @media (max-width: 767px) {
    font-size: 25px;
  }
}

.btn_center {
  display: flex;
  justify-content: center;
}

.mt-section {
  margin-top: 100px;
}

.st0 {
  opacity: 0.5;
}

.st1 {
  fill: none;
  stroke: #F63737;
  stroke-width: 0.5;
}

.st2 {
  fill: #F63737;
}

