@import './variables';
.bc_cta {
  position: relative;
  &:before {
    content: '';
    width: 1098px;
    height: 1098px;
    position: absolute;
    top: -644px;
    left: -257px;
    z-index: -1;
    background: url("../img/blur/blur2.svg") no-repeat center;
    @media (max-width: 991px) {
      max-width: 100%;
      background-size: contain;
    }
    @media (max-width: 767px) {
      left: -150px;
      top: -344px;
      height: 700px;
    }
  }
  &:after {
    content: '';
    width: 731px;
    height: 699px;
    position: absolute;
    top: -170px;
    right: 167px;
    z-index: -1;
    background: url("../img/oct_contact.svg") no-repeat center;
    @media (max-width: 991px) {
      max-width: 100%;
      background-size: contain;
      right: 0;
    }
    @media (max-width: 767px) {
      height: 350px;
      top: -60px;
    }
  }
}
.cta_section {
  .section_title {
    text-align: center;
  }

  p {
    text-align: center;
    font-weight: 400;
    font-size: 32px;
    margin-top: 130px;
    margin-bottom: 55px;
    @media (max-width: 991px) {
      font-size: 25px;
      margin-top: 70px;
    }
    @media (max-width: 767px) {
      font-size: 16px;
      margin-top: 20px;
    }
  }

  .cta-btns {
    display: flex;
    justify-content: center;
    gap: 55px;
    @media (max-width: 991px) {
      flex-wrap: wrap;
    }
    @media (max-width: 767px) {
      gap: 20px;
    }

    a {
      min-width: 253px;
      text-align: center;

      @media (max-width: 767px) {
        width: 100%;
        min-width: auto;
        max-width: 265px;
      }
    }
  }
}