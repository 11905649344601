@import './variables';

.modal_container {
  background: $dark_color;
  border: 1px solid #F63737;
  border-radius: 20px;
  padding: 30px 70px;
  box-shadow: 0 0px 8px 1px #F63737;
  width: max-content;
  max-width: 90%;

  @media (max-width: 767px) {
    padding: 25px 25px;
  }

  .close_modal {
    position: absolute;
    right: 15px;
    top: 15px;
    background: url("../img/close.svg") no-repeat center;
    width: 30px;
    height: 30px;
    cursor: pointer;
  }

  &:focus-visible {
    outline: none;
  }

  h2 {
    font-size: 40px;
    text-align: center;

    @media (max-width: 991px) {
      font-size: 35px;
    }
    @media (max-width: 767px) {
      font-size: 25px;
    }
  }

  .modal_contacts {
    margin-top: 30px;

    &__links {
      display: flex;
      gap: 20px;
      align-items: center;
      justify-content: center;

      a {
        position: relative;
        height: 30px;
        display: flex;
        align-items: center;

        &:before {
          position: absolute;
          top: 0px;
          left: 0;
          height: 0;
          overflow: hidden;
          transition: 0.5s ease-in-out;
          content: "";
          width: 100%;
        }

        &:nth-child(1) {
          &:before {
            background: url("../img/media/telegram_c.svg") no-repeat top center;
            background-size: 30px;
          }
        }

        &:nth-child(2) {
          height: 34px;

          &:before {
            background: url("../img/media/insta_c.svg") no-repeat top 2px center;
            background-size: 30px;
          }
        }

        &:nth-child(3) {
          &:before {
            background: url("../img/media/linkedin_c.svg") no-repeat top center;
            background-size: 30px;
          }
        }

        &:nth-child(4) {
          &:before {
            background: url("../img/media/email_c.svg") no-repeat top center;
            background-size: 30px;
          }
        }

        &:hover:before {
          height: 100%;
        }
      }

      img {
        width: 30px;
      }
    }
  }

  .divider {
    display: block;
    position: relative;
    text-align: center;
    margin-top: 30px;
    margin-bottom: 30px;
    @media (max-width: 767px) {
      margin-top: 20px;
      margin-bottom: 20px;
    }

    &:before, &:after {
      content: "";
      position: absolute;
      top: calc(50% - 0.5px);
      height: 1px;
      background: $main_color;
      width: 40%;
    }

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }
  }

  .modal_form {
    form {
      display: flex;
      flex-direction: column;

      label {
        font-size: 22px;
        margin-bottom: 20px;
      }

      .input_section {
        position: relative;
        width: 100%;
      }

      .error_message {
        position: absolute;
        bottom: 20px;
        font-size: 14px;
        color: $main_color;
      }
      .success_message {
        position: absolute;
        bottom: 20px;
        font-size: 14px;
        color: $color_white;
      }

      .form_send {
        font-size: 22px;
        line-height: 111%;
        padding: 21px 35px;
        border: 1px solid #fff;
        border-radius: 50px;
        display: inline-block;
        cursor: pointer;
        user-select: none;
        width: max-content;
        background: transparent;
        color: $main_text_color;
        margin: 0 auto;
        transition: background .3s ease, color 0.3s ease;

        @media (max-width: 767px) {
          font-size: 18px;
        }

        &:hover {
          background: rgba(248, 248, 248, 0.28);
          color: #fff;
        }
      }

      input {
        border: 1px solid #D9D9D9;
        border-radius: 10px;
        padding: 10px;
        background: transparent;
        font-size: 20px;
        color: #fff;
        margin-bottom: 40px;
        width: 100%;
        @media (max-width: 767px) {
          font-size: 18px;
        }

        &:focus-visible {
          outline: none;
        }

        &.error {
          border: 1px solid $main_color;
        }
      }

      .Mui-checked {
        color: $main_color;
      }

    }
  }
}