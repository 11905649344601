@import './variables';

.media-container {
  position: sticky;
  bottom:50px;
  left: 30px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  z-index: 99;
  width: 20px;
  @media (max-width: 1300px) {
    display: none;
  }

  a {
    position: relative;
    height: 20px;
    display: flex;
    align-items: center;

    &:before {
      position: absolute;
      top: 0px;
      left: 0;
      height: 0;
      overflow: hidden;
      transition: 0.5s ease-in-out;
      content: "";
      width: 100%;
    }
    &:nth-child(1) {
      &:before {
        background: url("../img/media/telegram_c.svg") no-repeat top center;
        background-size: 20px;
      }
    }
    &:nth-child(2) {
      height: 24px;

      &:before {
        background: url("../img/media/insta_c.svg") no-repeat top 2px center;
        background-size: 20px;
      }
    }
    &:nth-child(3) {
      &:before {
        background: url("../img/media/email_c.svg") no-repeat top center;
        background-size: 20px;
      }
    }
    &:nth-child(4) {
      &:before {
        background: url("../img/media/linkedin_c.svg") no-repeat top center;
        background-size: 20px;
      }
    }
    &:hover:before {
      height: 100%;
    }
  }
  img {
    width: 20px;
    height: 20px;
    color: #cbcaca;
  }
}

.media_mobile .media-container{
  display: none;

  @media (max-width: 991px) {
    display: flex;
    flex-direction: row;
    gap: 0;
    justify-content: space-between;
    position: relative;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 1px solid $main_color;
    align-items: center;
  }

  a:before {
    display: none;
  }
}